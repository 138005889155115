import React, { useEffect, useState } from "react";
import { useCookies } from 'react-cookie';


export default function SetPage(){

    const [cookies] = useCookies(['uuid']);

    // 쿠키에서 UUID를 이용하여 사용자 정보 가져오기
    const[uid,setUID] = useState(0);
    const[nickname,setNickname] = useState('');

    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
      if (!cookies.uuid) {
        alert('로그인 정보가 존재하지 않습니다. 로그인 페이지로 이동합니다.');
        window.location.href = `/Login_ats`;
        return;
      } else {
        const uuid = cookies.uuid;
        fetchUserInfo(uuid);
      }
    }, []);

    useEffect(() => {
      console.log('클라이언트 uuid', cookies.uuid);
    }, [cookies.uuid]);
  
    const fetchUserInfo = (uuid) => {
      fetch(`/SetPage/user?uuid=${uuid}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('서버 응답 실패');
          }
          return response.json();
        })
        .then(data => {
          const user = data[0];
          setUID(user.uid);
          setNickname(user.nickname);
          fetchLists(user.uid);
        })
        .catch(error => {
          alert('로그인 정보가 존재하지 않습니다. 로그인 페이지로 이동합니다.');
          window.location.href = `/Login_ats`;
          return;
        });
    };
    
    // selectedlevel, entry, waiting list 받아오기
    const fetchLists = (uid) => {
      fetch(`/startcheck/searchlevel?uid=${uid}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('서버 응답 실패');
          }
          return response.json();
        })
        .then(data => {
          if(data[0].super_user === "8"){
            setLevelCheck(data[0].super_user);  
          }else{
            setLevelCheck(data[0].selectedLevel);
          }
          console.log(setlevel);
        })
        .catch(error => {
          console.error('Entry List 가져오기 오류:', error);
          alert(error);
        });

      fetch(`/startcheck/list_entry?uid=${uid}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('서버 응답 실패');
          }
          return response.json();
        })
        .then(data => {
          updateList(data, e_items, setE_Items);
        })
        .catch(error => {
          console.error('Entry List 가져오기 오류:', error);
          alert(error);
        });
  
      fetch(`/startcheck/list_wait?uid=${uid}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('서버 응답 실패');
          }
          return response.json();
        })
        .then(data => {
          updateList(data, w_items, setW_Items);
        })
        .catch(error => {
          console.error('Waiting List 가져오기 오류:', error);
          alert(error);
        });
    };
  
    const updateList = (data, list, setList) => {
      for (let i = 0; i < data.length; i++) {
        if (!isItemInList(data[i].symbol, list)) {
          setList(prevList => [...prevList, data[i].symbol]);
        }
      }
    };

    const isItemInList = (item, list) => {
      return list.includes(item);
    };

    const[seed,setSeed] = useState('basic');

    const[symbol,setSymbol] = useState('default');
    const[leverage,setLeverage] = useState(10);
    const[firstusdt,setFirstUsdt] = useState(5);


    const[halfusdt, setHalfUsdt] = useState(2);
    const[halfratio, setHalfRatio] = useState(0.4);
    const[halfgrowper, setHalfGrowPer] = useState(100);
    const[halfnum, setHalfNum] = useState(3);


    const[lineusdt, setLineUsdt] = useState(20);
    const[lineadd, setLineAdd] = useState(100);
    const[linetrend, setLineTrend] = useState(0.05);
    const[objbenefit, setObjBenefit] = useState(0.3);
    const[usdtratio, setUsdtRatio] = useState(5.0);
    const[addnum, setAddNum] = useState(3);

    const[leverageValid,setLeverageValid] = useState(true);
    const[firstusdtValid,setFirstUsdtValid] = useState(true);


    const[halfusdtValid, setHalfUsdtValid] = useState(true);
    const[halfratioValid, setHalfRatioValid] = useState(true);
    const[halfgrowperValid, setHalfGrowPerValid] = useState(true);
    const[halfnumValid, setHalfNumValid] = useState(true);


    const[lineusdtValid, setLineUsdtValid] = useState(true);
    const[lineaddValid, setLineAddValid] = useState(true);
    const[linetrendValid, setLineTrendValid] = useState(true);
    const[objbenefitValid, setObjBenefitValid] = useState(true);
    const[usdtratioValid, setUsdtRatioValid] = useState(true);
    const[addnumValid, setAddNumValid] = useState(true);


    const[trend,setTrend] = useState('trend');
    const[direction,setDirection] = useState('direction');
    const[entry_standard,setEntryStandard] = useState('trendstrength');

    const[setlevel, setLevelCheck] = useState(0);

    const[notAllow, setNotAllow] = useState(true);

    const[e_items, setE_Items] = useState([]);
    const[w_items, setW_Items] = useState([]);

    const handleCheckboxChange = (event) => {
      setIsChecked(event.target.checked);
    };

    const handleTrend = (e) => {
      setTrend(e.target.value);
        };

    const handleDirection = (e) => {
      setDirection(e.target.value);
        };
    
    const handleEntryStandard = (e) => {
      setEntryStandard(e.target.value);
      setDirection("twoway")
        };
    
    const[search,setSearch] = useState(false);

    const[trade_start,setTradeStart] = useState(false);

    
    const[benefit_stop,setBenefit_stop] = useState(false);
    const[benefit_cancel,setBenefit_cancel] = useState(false);

    const[currentCoin_stop,setCurrentCoin_stop] = useState(false);
    const[allCoin_stop,setAllCoin_stop] = useState(false);

    const[end,setEnd] = useState(false);



    const handleLeverage = (e) => {
      const newLeverage = e.target.value.trim();
      setLeverage(newLeverage);
        const regex = 
        /^\d*$/;
        if (regex.test(newLeverage)) {
          setLeverageValid(true);
          } 
        else {
          setLeverageValid(false);
          }
        };

    const handleFirstUsdt = (e) => {
      const newFirstusdt = e.target.value.trim();
      setFirstUsdt(newFirstusdt);
        const regex = 
        /^\d*$/;
        if (regex.test(newFirstusdt)) {
          setFirstUsdtValid(true);
          } 
        else {
          setFirstUsdtValid(false);
          }
        };



    const handleHalfUsdt= (e) => {
      const newHalfusdt = e.target.value.trim();
      setHalfUsdt(newHalfusdt);
        const regex = 
        /^\d*$/;
        if (regex.test(newHalfusdt)) {
          setHalfUsdtValid(true);
          } 
        else {
          setHalfUsdtValid(false);
          }
        };

    const handleHalfRatio = (e) => {
      const newHalfratio = e.target.value.trim();
      setHalfRatio(newHalfratio);
        const regex = 
        /^\d*\.?\d*$/;
        if (regex.test(newHalfratio)) {
          setHalfRatioValid(true);
          } 
        else {
          setHalfRatioValid(false);
          }
        };

    const handleHalfGrowPer = (e) => {
      const newHalfgrowper = e.target.value.trim();
      setHalfGrowPer(newHalfgrowper);
        const regex = 
        /^\d*\.?\d*$/;
        if (regex.test(newHalfgrowper)) {
          setHalfGrowPerValid(true);
          } 
        else {
          setHalfGrowPerValid(false);
          }
        };

    const handleHalfNum = (e) => {
      const newHalfnum = e.target.value.trim();
      setHalfNum(newHalfnum);
        const regex = 
        /^\d*$/;
        if (regex.test(newHalfnum)) {
          setHalfNumValid(true);
          } 
        else {
          setHalfNumValid(false);
          }
        };
        


    const handleLineUsdt = (e) => {
      const newLineusdt = e.target.value.trim();
      setLineUsdt(newLineusdt);
        const regex = 
        /^\d*$/;
        if (regex.test(newLineusdt)) {
          setLineUsdtValid(true);
          } 
        else {
          setLineUsdtValid(false);
          }
        };

    const handleLineAdd = (e) => {
      const newLineadd = e.target.value.trim();
      setLineAdd(newLineadd);
        const regex = 
        /^\d*\.?\d*$/;
        if (regex.test(newLineadd)) {
          setLineAddValid(true);
          } 
        else {
          setLineAddValid(false);
          }
        };

    const handleLineTrend = (e) => {
      const newLinetrend = e.target.value.trim();
      setLineTrend(newLinetrend);
        const regex = 
        /^\d*\.?\d*$/;
        if (regex.test(newLinetrend)) {
          setLineTrendValid(true);
          } 
        else {
          setLineTrendValid(false);
          }
        };

    const handleObjBenefit = (e) => {
      const newObjbenefit = e.target.value.trim();
      setObjBenefit(newObjbenefit);
        const regex = 
        /^\d*\.?\d*$/;
        if (regex.test(newObjbenefit)) {
          setObjBenefitValid(true);
          } 
        else {
          setObjBenefitValid(false);
          }
        };

    const handleUsdtRatio = (e) => {
      const newUsdtratio = e.target.value.trim();
      setUsdtRatio(newUsdtratio);
        const regex = 
        /^\d*\.?\d*$/;
        if (regex.test(newUsdtratio)) {
          setUsdtRatioValid(true);
          } 
        else {
          setUsdtRatioValid(false);
          }
        };

    const handleAddNum = (e) => {
      const newAddnum = e.target.value.trim();
      setAddNum(newAddnum);
        const regex = 
        /^\d*$/;
        if (regex.test(newAddnum)) {
          setAddNumValid(true);
          } 
        else {
          setAddNumValid(false);
          }
        };
    
     
    const addListItem = (newItem, listSetter) => {
      listSetter(prevList => [...prevList, newItem]);
    };

    const removeListItem = (itemName, listSetter) => {
      listSetter(prevList => prevList.filter(item => item !== itemName));
    };
    
    const onClickCoin = (coin) => {
      setSymbol(coin);
      onClickSearch(coin);
    }

    useEffect(() => {

      if(leverageValid && firstusdtValid && halfusdtValid && halfratioValid && halfgrowperValid &&
        halfnumValid && lineusdtValid && lineaddValid && linetrendValid && 
        objbenefitValid && usdtratioValid && addnumValid) {
        setNotAllow(false);
        return;
      }
      setNotAllow(true);
    }, [leverageValid, firstusdtValid, halfusdtValid, halfratioValid, halfgrowperValid,
      halfnumValid, lineusdtValid, lineaddValid, linetrendValid, 
      objbenefitValid, usdtratioValid, addnumValid]);

    useEffect(() => {
        console.log(symbol);
    },[symbol]);

    const onClickSearch = (symbol) => {
      let urlName
      const str = symbol;
      console.log(str);
      const uidurl = uid;
      if(str === 'default'){
        urlName = 'default'
      }else{
        const index = str.indexOf('/');
        urlName = str.substring(0,index);
      }  
      fetch(`/setdata/${uidurl}/${urlName}/${setlevel}`)
      .then((response) => {
        if(!response.ok) {
          throw new Error('400 아니면 500 에러남');
        }
        return response.json()
      })
      .then((result) => {
        console.log('db에서 받아온 데이터',result);
        setLeverage(result[0].leverage_info);
        setFirstUsdt(result[0].first_entry_usdt);
        setHalfUsdt(result[0].half_entry_usdt)
        setHalfRatio(result[0].half_ratio);
        setHalfGrowPer(result[0].half_grow_per);
        setHalfNum(result[0].half_num);

        setLineUsdt(result[0].entry_usdt);
        setLineAdd(result[0].entry_per);
        
        setLineTrend(result[0].buy_ratio);
        setObjBenefit(result[0].magin);
        setUsdtRatio(result[0].ratio);

        setAddNum(result[0].num);
        setTradeStart(result[0].trade_start);
        setTrend(result[0].trend);
        setDirection(result[0].direction)
        setEntryStandard(result[0].entry_standard)
        setBenefit_stop(result[0].current_symbol_stop);
        setBenefit_cancel(result[0].clean_up_profit_cancel);
        setCurrentCoin_stop(result[0].current_coin_clear);
        setAllCoin_stop(result[0].all_coin_clear);
        setEnd(result[0].end);

        const e_checklist = isItemInList(result[0].symbol, e_items);
        const w_checklist = isItemInList(result[0].symbol, w_items);
        
        if(str !== 'default'){
          result.forEach(item => {
            console.log('item',item)
            if ((item.trade_start === 1 || item.trade_start === '1') && e_checklist === false) {
              addListItem(item.symbol, setE_Items);
            }
            if ((item.trade_start === 0 || item.trade_start === '0') && e_checklist === true) {
              removeListItem(item.symbol, setE_Items);
            }
          
            // Waiting coin 리스트 삽입 및 삭제
            if ((item.current_symbol_stop === 1 || item.current_symbol_stop === '1') && w_checklist === false) {
              addListItem(item.symbol, setW_Items);
            }
            if ((item.current_symbol_stop === 0 || item.current_symbol_stop === '0') && w_checklist === true) {
              removeListItem(item.symbol, setW_Items);
            }
          });
        };

        console.log('e_items',e_items);
        console.log('e_checklist',e_checklist);
      })
      .catch((error) => {
        console.log('에러남',error);
      })
    }

    const onClickStart = () => {

      let ratio_check
      ratio_check = halfratio * halfnum

      if (symbol === "default") {
        alert('코인을 먼저 선택하세요!');
        return; // Stop execution if the condition is met
      }
      
      if (symbol === "BTC/USDT:USDT" && firstusdt < 7) {
        alert('BTC/USDT:USDT는 최초 진입금액은 최소 7 USDT 이상이어야 합니다.');
        return; // Stop execution if the condition is met
      }

      if (symbol === "ETH/USDT:USDT" && firstusdt < 4) {
        alert('ETH/USDT:USDT는 최초 진입금액은 최소 4 USDT 이상이어야 합니다.');
        return; // Stop execution if the condition is met
      }

      if (symbol === "ETC/USDT:USDT" && firstusdt < 3) {
        alert('ETC/USDT:USDT는 최초 진입금액은 최소 3 USDT 이상이어야 합니다..');
        return; // Stop execution if the condition is met
      }

      if (symbol === "LINK/USDT:USDT" && firstusdt < 2) {
        alert('LINK/USDT:USDT는 최초 진입금액은 최소 2 USDT 이상이어야 합니다..');
        return; // Stop execution if the condition is met
      }

      if (symbol === "SOL/USDT:USDT" && firstusdt < 2) {
        alert('SOL/USDT:USDT는 최초 진입금액은 최소 2 USDT 이상이어야 합니다..');
        return; // Stop execution if the condition is met
      }

      if (symbol === "SOL/USDT:USDT" && firstusdt < 2) {
        alert('SOL/USDT:USDT는 최초 진입금액은 최소 2 USDT 이상이어야 합니다..');
        return; // Stop execution if the condition is met
      }

      if (objbenefit > 50) {
        alert('목표수익률은 50 이상 설정되지 않습니다. 목표 수익률이 너무 크면 프로그램이 정상 작동되지 않습니다. 수익률을 재설정하세요!');
        return; // Stop execution if the condition is met
      }

      if (halfgrowper > 200 || lineadd > 200) {
        alert('입력한 라인별 증가율이 200을 넘었습니다. 너무 큰 증가율은 시드의 빠른 청산으로 이어질 수도 있습니다. 주의하세요!');
      }

      if (ratio_check >= usdtratio) {
        alert('현재 설정값은 Inner line과 Outer line이 중첩됩니다. 원하는 설정값이 맞는지 확인하고 진행하세요!');
      }

      if (symbol === "PEPE/USDT:USDT") {
        alert('PEPE/USDT:USDT는 변동폭이 크기 때문에 시드의 손실이 많이 발생할 수 있습니다. 참고하고 진행하세요!');
      }

      if (symbol === "PEOPLE/USDT:USDT") {
        alert('PEOPLE/USDT:USDT는 변동폭이 크기 때문에 시드의 손실이 많이 발생할 수 있습니다. 참고하고 진행하세요!');
      }

      if (symbol === "WIF/USDT:USDT") {
        alert('WIF/USDT:USDT는 변동폭이 크기 때문에 시드의 손실이 많이 발생할 수 있습니다. 참고하고 진행하세요!');
      }

      const confirmed = window.confirm(symbol + '의 매매를 시작하시겠습니까?');

      if (confirmed) {
        fetch('/start', {
          method : 'POST',
          headers: {
            'Content-Type' : 'application/json',
          },
          body: JSON.stringify({
            uid,
            symbol,
            search,
            leverage,
            firstusdt,
            halfusdt,
            halfratio,
            halfgrowper,
            halfnum,
            lineusdt,
            lineadd,
            linetrend,
            objbenefit,
            usdtratio,
            addnum,
            trade_start,
            trend,
            direction,
            entry_standard,
            benefit_stop,
            benefit_cancel,
            currentCoin_stop,
            allCoin_stop,
            end,
            }),
         });
       };
      onClickSearch(symbol);
    };

    const autoTradeStart = (seed) => {

      if (seed === "basic") {
        alert('자동 매매 시드를 먼저 선택하세요!');
        return; // Stop execution if the condition is met
      }

      const uidurl = uid;

      alert('시드 자동 셋팅 매매는 안정적인 매매법의 추천값이지 절대값은 아님을 알려드립니다. 시장의 변동성에 따라 손실이 발생할 수 있음을 미리 인지하시고 본인의 판단에 따라 적절히 활용하세요');

      const confirmed = window.confirm(seed + ' USDT 기준의 자동매매를 시작하시겠습니까?');

      if (confirmed) {
        console.log(uidurl)
        fetch(`/auto_start/${uidurl}/${seed}`)
        .then((response) => {
          if(!response.ok) {
            throw new Error('400 아니면 500 에러남');
          }
          return response.json()
        })
        .then((result) => {
          console.log('auto_start에서 받아온 데이터',result);
        })
        .catch((error) => {
          console.log('에러남',error);
        })
        window.location.reload()
        // onClickSearch('default');
      };
      };

    // 익절 후 정리
    const onClickBenefit_stop = (symbol) => {
      const confirmed = window.confirm('Do you want to clean up the selected coin after profiting?');

      if (confirmed) {
        setBenefit_stop(true);
        let urlName
        const str = symbol;
        console.log(str);
        const uidurl = uid;
        if(str === 'default'){
          urlName = 'default'
        }else{
          const index = str.indexOf('/');
          urlName = str.substring(0,index);
        }
        fetch(`/setdata_benefit/${uidurl}/${urlName}/${setlevel}`)
        .then((response) => {
          if(!response.ok) {
            throw new Error('400 아니면 500 에러남');
          }
          return response.json()
        })
        .then((result) => {
          console.log('benefit에서 받아온 데이터',result);
        })
        .catch((error) => {
          console.log('에러남',error);
          })
        };
      onClickSearch(symbol);
    }

    // 익절 후 정리 취소 
    const onClickBenefit_cancel = (symbol) => {
      const confirmed = window.confirm('Do you want to cancel clearing after profiting the selected coins?');

      if (confirmed) {
        setBenefit_cancel(true);
        let urlName
        const str = symbol;
        console.log(str);
        const uidurl = uid;
        if(str === 'default'){
          urlName = 'default'
        }else{
          const index = str.indexOf('/');
          urlName = str.substring(0,index);
        }
        fetch(`/setdata_benefit_cancel/${uidurl}/${urlName}/${setlevel}`)
        .then((response) => {
          if(!response.ok) {
            throw new Error('400 아니면 500 에러남');
          }
          return response.json()
        })
        .then((result) => {
          console.log('benefit_cancel에서 받아온 데이터',result);
        })
        .catch((error) => {
          console.log('에러남',error);
        })
      };
      onClickSearch(symbol);
    }

    // 현재 코인 정리 
    const onClickCurrentCoin_stop = (symbol) => {
      const confirmed = window.confirm('Would you like to liquidate the selected coins right away?');

      if (confirmed) {
        setCurrentCoin_stop(true);
        const str = symbol;
        console.log(str);
        const uidurl = uid;
        const index = str.indexOf('/');
        const urlName = str.substring(0,index);
        fetch(`/setdata_current_coin_clear/${uidurl}/${urlName}`)
        .then((response) => {
          if(!response.ok) {
            throw new Error('400 아니면 500 에러남');
          }
          return response.json()
        })
        .then((result) => {
          console.log('current_stop에서 받아온 데이터',result);
        })
        .catch((error) => {
          console.log('에러남',error);
        })
      };
      onClickSearch(symbol);
    }

    //진입중인 모든 코인 정리
    const onClickAllCoin_stop = () => {
      const confirmed = window.confirm('Do you really want to liquidate all coins that are currently entering the market?');

      if (confirmed) {
        setAllCoin_stop(true);
        const str = symbol;
        console.log(str);
        const uidurl = uid;
        const index = str.indexOf('/');
        const urlName = str.substring(0,index);
        fetch(`/setdata_all_coin_clear/${uidurl}/${urlName}`)
        .then((response) => {
          if(!response.ok) {
            throw new Error('400 아니면 500 에러남');
          }
          return response.json()
        })
        .then((result) => {
          console.log('current_stop에서 받아온 데이터',result);
        })
        .catch((error) => {
          console.log('에러남',error);
        })
      };
      onClickSearch(symbol);
    }

    //로그아웃
    const onClickEnd = () => {
        const uuid = cookies.uuid; // cookies에서 uuid를 얻어옴
        if (!uuid) {
          alert('로그인 정보가 존재하지 않습니다.');
          return;
        }
        // 로그아웃 요청을 서버로 보냅니다.
        fetch('/logout', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ userId: uuid })
        })
        .then(response => {
          if (!response.ok) {
            throw new Error('로그아웃 요청 실패');
          }
          console.log('로그아웃 성공');
          alert('Logout is successful.');
          window.location.href = '/';
        })
        .catch(error => {
          console.error('로그아웃 요청 중 오류 발생:', error);
        });
      // UUID를 서버로 보내는 예시 코드

    }

    return (
        <div className="page_sp">
          <div className="title_top_sp">
            <div></div>
            <a href="/explanation" className="explanation-box" target="_blank" rel="noopener noreferrer">프로그램<br/>사용설명서</a>
            <div className="titleWrap_sp">
            Just Jump trade set (Web version)</div>
            <div className="inputTitle_uid">
                <span>UID : {uid}</span>
                <span>{nickname}님 환영합니다</span>
            </div>
          </div>

          <div className="line_top"></div>

          <div className="centered">
            <div className="inputTitle_sp_price">Auto setting
              <input type="checkbox" onChange={handleCheckboxChange}/>
              <select className="select_price" disabled={!isChecked} value = {seed} onChange={(e) => {setSeed(e.target.value)}}>
                <option value="basic">Set seed</option>
                <option value="1,500">1,500</option>
                <option value="2,200">2,200</option>
                <option value="3,500">3,500</option>
                <option value="5,000">5,000</option>
                <option value="7,000">7,000</option>
                <option value="10,000">10,000</option>
                <option value="14,500">14,500</option>
                <option value="21,000">21,000</option>
              </select>
              USDT
            </div>
            <button onClick={() => autoTradeStart(seed)} disabled={!isChecked} className="button_price_select">
                자동 매매 시작
            </button>
          </div>

            <div className="container">
              <div className="column">
              {/* {setlevel <= 7 && (
                  <div className="popupMessage_setpage">
                    <p>포지션 진입 코인과 진입은 
                      셋팅된 레벨에 따라 다르게 진입합니다.<br/><br/>
                      코인익절 후 정리 : Entry coins 의 리스트 코인을 waiting cois 리스트로 이동합니다.<br/><br/>
                      취 소 : Waiting coins의 리스트 코인을 Entry coins 리스트로 이동합니다.<br/><br/>
                      Entry coins의 리스트에 있는 코인은 익절 후 지속적으로 재진입합니다.<br/><br/>
                      Waiting coins의 리스트에 있는 코인은 익절 후 진입 중지합니다.
                    </p>
                  </div>
              )} */}

            <div className="button_benefit">
              <select className="usdt_select" value = {symbol} onChange={(e) => {setSymbol(e.target.value); onClickSearch(e.target.value);}}>
                <option value="default">USDT Select</option>
                <option value="ATOM/USDT:USDT">ATOM/USDT</option>
                <option value="BCH/USDT:USDT">BCH/USDT</option>
                <option value="BTC/USDT:USDT">BTC/USDT</option>
                <option value="DOGE/USDT:USDT">DOGE/USDT</option>
                <option value="DOT/USDT:USDT">DOT/USDT</option>
                <option value="EOS/USDT:USDT">EOS/USDT</option>
                <option value="ETC/USDT:USDT">ETC/USDT</option>
                <option value="ETH/USDT:USDT">ETH/USDT</option>
                <option value="PEPE/USDT:USDT">PEPE/USDT</option>
                <option value="PEOPLE/USDT:USDT">PEOPLE/USDT</option>
                <option value="TRX/USDT:USDT">TRX/USDT</option>
                <option value="WIF/USDT:USDT">WIF/USDT</option>
                <option value="XLM/USDT:USDT">XLM/USDT</option>
                <option value="XRP/USDT:USDT">XRP/USDT</option>
              </select>
            <button onClick={() => onClickSearch(symbol)} disabled={!symbol} className="button_benefit_c">
                Search
            </button>

            </div>
            <div className="contentWrap_sp">
                <div className="leverageWrap_sp">레버리지 셋팅
                <div style={{marginLeft:"auto"}}>X</div>
                  <div className="lvWrap_sp">
                      <input 
                      type='number'
                      className="input_sp"
                      placeholder="Enter only integers"
                      value={leverage}
                      disabled={setlevel <= 7}
                      onChange={handleLeverage} />
                  </div>
                </div>
                <div className="errorMessageWrap_sp">
                    {!leverageValid && leverage.length > 0 && (
                        <div>Please enter only integers.</div>
                )}
            </div>

            <div style={{ marginTop: "10px"}} className="inputTitle_sp">최초 진입금액
                <div className="inputWrap_sp">
                    <input 
                    type='number'
                    className="input_sp"
                    placeholder="Enter only integers"
                    value={firstusdt}
                    disabled={setlevel <= 7}
                    onChange={handleFirstUsdt} />
                </div>
                </div>
                <div className="errorMessageWrap_sp">
                    {!firstusdtValid && firstusdt.length > 0 && (
                        <div>Please enter only integers.</div>
                    )}
            </div>

            <div style={{ marginTop: "10px"}} className="inputTitle_sp">목표수익률(%)
                <div className="inputWrap_sp">
                    <input 
                    type='number'
                    className="input_sp"
                    placeholder="Enter only decimal format"
                    value={objbenefit}
                    disabled={setlevel <= 7}
                    onChange={handleObjBenefit} />
                </div>
              </div>
                <div className="errorMessageWrap_sp">
                    {!objbenefitValid && objbenefit.length > 0 && (
                        <div>Please enter in decimal format only.</div>
                    )}
              </div>










            <div className="line"></div>
            
            <div style={{ marginTop: "10px", wordBreak: "break-all"}} className="inputTitle_sp">Inner line 진입금액
              <div className="inputWrap_sp">
                    <input 
                    type='number'
                    className="input_sp"
                    placeholder="Enter only integers"
                    value={halfusdt}
                    disabled={setlevel <= 7}
                    onChange={handleHalfUsdt} />
                </div>
              </div>
                <div className="errorMessageWrap_sp">
                    {!halfusdtValid && halfusdt.length > 0 && (
                        <div>Please enter only integers.</div>
                    )}
            </div>

            <div style={{ marginTop: "10px"}} className="inputTitle_sp">Inner line 진입간격(%)
                <div className="inputWrap_sp">
                    <input 
                    type='number'
                    className="input_sp"
                    placeholder="Enter only decimal format"
                    value={halfratio}
                    disabled={setlevel <= 7}
                    onChange={handleHalfRatio} />
                </div>
              </div>
                <div className="errorMessageWrap_sp">
                    {!halfratioValid && halfratio.length > 0 && (
                       <div>Please enter in decimal format only.</div>
                        )}
            </div>

            <div style={{ marginTop: "10px"}} className="inputTitle_sp">Inner line 증가율(%)
                <div className="inputWrap_sp">
                    <input 
                    type='number'
                    className="input_sp"
                    placeholder="Enter only decimal format"
                    value={halfgrowper}
                    disabled={setlevel <= 7}
                    onChange={handleHalfGrowPer} />
                </div>
              </div>
                <div className="errorMessageWrap_sp">
                    {!halfgrowperValid && halfgrowper.length > 0 && (
                       <div>Please enter in decimal format only.</div>
                        )}
            </div>

            <div style={{ marginTop: "10px"}} className="inputTitle_sp">Inner line 진입횟수
                <div className="inputWrap_sp">
                    <input 
                    type='number'
                    className="input_sp"
                    placeholder="Enter only integers"
                    value={halfnum}
                    disabled={setlevel <= 7}
                    onChange={handleHalfNum} />
                </div>
              </div>
                <div className="errorMessageWrap_sp">
                    {!halfnumValid && halfnum.length > 0 && (
                        <div>Please enter only integers.</div>
                    )}
            </div>

        







            <div className="line"></div>

            <div style={{ marginTop: "10px"}} className="inputTitle_sp">Outer line 진입금액
                <div className="inputWrap_sp">
                    <input 
                    type='number'
                    className="input_sp"
                    placeholder="Enter only integers"
                    value={lineusdt}
                    disabled={setlevel <= 7}
                    onChange={handleLineUsdt} />
                </div>
              </div>
                <div className="errorMessageWrap_sp">
                    {!lineusdtValid && lineusdt.length > 0 && (
                       <div>Please enter only integers.</div>
                    )}
            </div>


            <div style={{ marginTop: "10px"}} className="inputTitle_sp">Outer line 진입간격(%)
                <div className="inputWrap_sp">
                    <input 
                    type='number'
                    className="input_sp"
                    placeholder="Enter only decimal format"
                    value={usdtratio}
                    disabled={setlevel <= 7}
                    onChange={handleUsdtRatio} />
                </div>
              </div>
                <div className="errorMessageWrap_sp">
                    {!usdtratioValid && usdtratio.length > 0 && (
                        <div>Please enter in decimal format only.</div>
                    )}
              </div>

            
            <div style={{ marginTop: "10px"}} className="inputTitle_sp">Outer line 증가율(%)
                <div className="inputWrap_sp">
                    <input 
                    type='number'
                    className="input_sp"
                    placeholder="Enter only decimal format"
                    value={lineadd}
                    disabled={setlevel <= 7}
                    onChange={handleLineAdd} />
                </div>
              </div>
                <div className="errorMessageWrap_sp">
                    {!lineaddValid && lineadd.length > 0 && (
                        <div>Please enter in decimal format only.</div>
                    )}
              </div>

              {/* <div style={{ marginTop: "10px"}} className="inputTitle_sp">진입추세강도(%)
                <div className="inputWrap_sp">
                    <input 
                    type='number'
                    className="input_sp"
                    placeholder="Enter only decimal format"
                    value={linetrend}
                    disabled={setlevel <= 8}
                    onChange={handleLineTrend} />
                </div>
              </div>
                <div className="errorMessageWrap_sp">
                    {!linetrendValid && linetrend.length > 0 && (
                        <div>Please enter in decimal format only.</div>
                    )}
              </div> */}


              <div style={{ marginTop: "10px"}} className="inputTitle_sp">Outer line 진입횟수
                <div className="inputWrap_sp">
                    <input 
                    type='number'
                    className="input_sp"
                    placeholder="Enter only integers"
                    value={addnum}
                    disabled={setlevel <= 7}
                    onChange={handleAddNum} />
                </div>
              </div>
                <div className="errorMessageWrap_sp">
                    {!addnumValid && addnum.length > 0 && (
                        <div>Please enter only integers.</div>
                    )}
              </div>

            </div>
            
            
                        

            <div className="line"></div>



            



            <div className="trend">
                <select className="trend_select" value={trend} onChange={handleTrend}>
                <option value="trends">Trend</option>
                <option value="countertrend">Countertrend</option>
                </select>
                <select style={{marginLeft:"auto"}} className="trend_select" disabled={entry_standard === 'webhook'} value={direction} onChange={handleDirection}>
                <option value="twoway">Twoway</option>
                <option value="long">Long</option>
                <option value="short">Short</option>
                </select>
                <select style={{marginLeft:"auto"}} className="trend_select" value={entry_standard} onChange={handleEntryStandard}>
                <option value="trendstrength">Trend strength</option>
                <option value="webhook">Web hook</option>
                </select>
            </div>

            <div className="button_benefit">
                <button onClick={() => onClickStart()} disabled={trade_start === 1 ||  benefit_stop === 1 || trade_start === '1' ||  benefit_stop === '1' || setlevel <= 7} className="bottomButton_sp">
                Trading start
                </button>
            </div>

            <div className="button_benefit">
                <button onClick={() => onClickBenefit_stop(symbol)} disabled={benefit_stop === 1 || trade_start === 0 || benefit_stop === '1' || trade_start === '0'} className="button_benefit_c">
                코인익절 후 정리
                </button>
                <button onClick={() => onClickBenefit_cancel(symbol)} disabled={benefit_stop === '0' || benefit_stop === 0} className="button_benefit_c">
                취 소
                </button>
            </div>

            <div className="button_benefit">
                <button onClick={() => onClickCurrentCoin_stop(symbol)} disabled={(symbol === 'default' && trade_start === 0 &&  benefit_stop === 0) || (symbol === 'default' && trade_start === '0' &&  benefit_stop === '0') || setlevel <= 7} className="bottomButton_sp">
                현재 코인 정리
                </button>
                {/* <button onClick={() => onClickAllCoin_stop()} disabled={symbol === 'default' && setlevel <= 7} className="button_benefit_c">
                모든 코인 정리
                </button> */}
            </div>

            <div className="button_benefit">
                <button onClick={() => onClickEnd()} disabled={notAllow} className="bottomButton_sp">
                로그 아웃
                </button>
             </div>
           </div>




          <div className="column">
              <div className="list_box_title">Entry coins</div>
              <ul className="list_box">
                {e_items.map((item, index) => (
                  <li key={index} onClick={() => onClickCoin(item)}>{item}</li>
                ))}
              </ul>
            </div>
          <div className="column">
            <div className="list_box_title">Waiting coins</div>
              <ul className="list_box">
                {w_items.map((item, index) => (
                    <li key={index} onClick={() => onClickCoin(item)}>{item}</li>
                  ))}
              </ul>
            </div>
          
          </div>
        </div>
    );
}